<template>
    <div
        id="sidebar"
        class="sticky right-0 top-[80px] hidden h-full overflow-y-auto overflow-x-hidden pr-[5px] pt-[50px] sm:hidden sm:pl-[59px] md:hidden lg:block lg:w-[400px] lg:px-[40px] xl:block xl:w-[476px] xl:pl-[59px] xl:pr-[58px]">
        <div class="relative top-[-50px] w-full overflow-x-scroll">
            <Link
                :href="route('gabber.create')"
                @click="trackCreateButton"
                class="mb-8 flex w-full max-w-[320px] items-center justify-center space-x-2 rounded-full bg-[#E86599] px-6 py-2 text-white transition-colors duration-200 hover:bg-pink-600">
                <img src="/images/waveform-icon.png" alt="Waveform" class="max-w-[24px]" />
                <span class="text-lg font-medium">Create your own Girlfriend</span>
            </Link>

            <!-- Sidebar content -->
            <div class="aside pb-[100px]">
                <ListCategory
                    v-if="trendingCategories"
                    :categories="trendingCategories"
                    :title="props.trendingCategoriesTitle"
                    :expandTitle="'Explore All'"
                    :expolreAll="true"
                    :expandable="false" />

                <button
                    type="button"
                    class="mb-2 mr-2 hidden rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    @click="showToasts">
                    Show Toasts
                </button>
                <SidebarAM />
                <div class="flex-grow pb-[30px] pt-[56px]">
                    <div class="flex flex-col items-center space-y-4">
                        <!-- Logged Out-->

                        <Link
                            v-if="!isLoggedIn"
                            data-drawer-hide="right-navigation"
                            class="mx-auto block w-full max-w-[360px] rounded-md border border-mintgreen text-center text-xl font-medium leading-[57px] tracking-wide hover:bg-mintgreen dark:text-white dark:hover:text-black"
                            :href="route('access', { type: 'join' })">
                            Join For Free or Login
                        </Link>
                        <Link
                            v-show="!props.isCreator"
                            class="mx-auto mb-10 mt-5 block w-full max-w-[360px] rounded-md bg-mintgreen text-center text-sm font-medium leading-[49px] tracking-wide text-black hover:bg-hovermintgreen sm:text-xl sm:leading-[57px]"
                            :href="route('creator.signup')">
                            {{ getVerifyText(props.verificationStatus) }}
                        </Link>
                    </div>
                </div>
                <div class=""><FooterLinks /></div>
            </div>
        </div>
    </div>
    <toast
        :show="show"
        transitionName="slide-fade"
        text="Toast in Blue"
        :transitionEnter="300"
        :transitionLeave="500"
        position="topRight"
        @closeButton="closeButton" />

    <toast
        :show="show"
        modifier="text-red-500 bg-red-100 m-auto"
        position="topRight"
        transitionName="fade"
        text="Toast in Red"
        :transitionEnter="300"
        :transitionLeave="500"
        @closeButton="closeButton" />
    <toast
        :show="show"
        modifier="text-green-500 bg-green-100 m-auto"
        position="topRight"
        transitionName="slide-fade"
        text="Toast in Green"
        :transitionEnter="300"
        :transitionLeave="500"
        @closeButton="closeButton" />
    <CreatorFlyout :show="showCreatorFlyout" @close="showCreatorFlyout = false" />
</template>
<script setup>
    import { Link } from '@inertiajs/vue3'
    import 'vue3-carousel/dist/carousel.css'
    import { onMounted, ref, computed } from 'vue'
    import { usePage } from '@inertiajs/vue3'
    import ListCategory from '@/Components/main-components/ListCategory.vue'
    import SidebarAM from '@/Components/ads/SidebarAM.vue'
    import Toast from '@/Components/Toast.vue'
    import FooterLinks from '@/Components/navigation/FooterLinks.vue'
    import CreatorFlyout from '@/Components/main-components/CreatorFly.vue'
    const { props } = usePage()
    const categoryTitle = 'Top Categories'
    const emit = defineEmits(['showFlyout'])

    const trendingCategories = props.trendingCategories
    const categoriesList = ref([])
    const generateCategories = () => {
        const categoryNames = props.categoryNames
        categoriesList.value = categoryNames
    }
    const isLoggedIn = ref(props.auth && props.auth.user && props.auth.user.name !== null)

    onMounted(() => {
        generateCategories()
    })
    const show = ref(false)
    const closeButton = () => {
        show.value = false
    }
    const showToasts = () => {
        show.value = true
    }
    const showCreatorFlyout = ref(false)

    const openCreatorFlyout = () => {
        showCreatorFlyout.value = true
    }
    const currentYear = ref(new Date().getFullYear())
    const toastExampleIcon = ` <svg
                    aria-hidden="true"
                    class="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill-rule="evenodd"
                        d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
                        clip-rule="evenodd"></path>
                </svg>`

    const getVerifyText = status => {
        switch (status) {
            case 0:
                return 'Become a Creator'
            case 1:
                return 'Complete Verification'
            case 2:
                return 'Pending Approval'
        }
    }

    const promoItems = [
        {
            text: "I'm Horny",
            route: 'https://natour.naughtyamerica.com/track/MTIwOTI5LjEwMDI1LjguOC43MTEuMC4wLjAuMA/scene/penny-barber-wants-the-married-ac-repair-man-to-check-her-temperature-32057',
            image: import.meta.env.VITE_CDN_URL + '/images/promo/IHW_PennyBarber_720x600.jpg',
        },
        {
            text: 'Pre-flight',
            route: 'https://natour.naughtyamerica.com/track/MTIwOTI5LjEwMDI1LjguOC43MTIuMC4wLjAuMA/scene/fit-and-limber-hottie-briana-moon-spreads-her-legs-wide-open-for-her-friends-husband-cock-32149',
            image: import.meta.env.VITE_CDN_URL + '/images/promo/MWHF_BrianaMoon_720x600.jpg',
        },
        {
            text: 'Nobody Does It Better',
            route: 'https://natour.naughtyamerica.com/track/MTIwOTI5LjEwMDI1LjguOC4wLjAuMC4wLjA',
            image: import.meta.env.VITE_CDN_URL + '/images/promo/NDIB_PROP_720x600.jpg',
        },
    ]

    const randomPromoItem = computed(() => {
        const randomIndex = Math.floor(Math.random() * promoItems.length)
        return promoItems[randomIndex]
    })

    const trackCreateButton = () => {
        fathom.trackEvent(isLoggedIn ? 'user_create_btn' : 'guest_create_btn')
    }
</script>

<style>
    #sidebar {
        max-height: calc(var(--vh, 1vh) * 100);
    }
</style>
